// Router
import {
 BrowserRouter, Routes, Route, Navigate 
} from 'react-router-dom';

import Live from '../views/Live/Live';
import Rooms from '../views/Rooms/Rooms';
import SignIn from '../views/SignIn/Login';
import CTCB2B from '../views/CTCB2B/CTCB2B';
import Groups from '../views/Groups/Groups';
import Sliders from '../views/Sliders/Sliders';
import Courses from '../views/Courses/Courses';
import Students from '../views/Students/Students';
import Landings from '../views/Landings/Landings';
import Teachers from '../views/Teachers/Teachers';
import Invoices from '../views/Invoices/Invoices';
import Dashboard from '../views/Dashboard/Dashboard';
import Guardians from '../views/Guardians/Guardians';
import AgeGroups from '../views/AgeGroups/AgeGroups';
import Discounts from '../views/Discounts/Discounts';
import GroupRooms from '../views/Groups/GroupRooms/GroupRooms';
import LearningPaths from '../views/LearningPaths/LearningPaths';
import ProjectDetail from '../views/CTCB2B/Projects/ProjectDetail';
import LandingDetail from '../views/Landings/Detail/LandingDetail';
import RoomDetailsV2 from '../views/Rooms/RoomDetail/V2/RoomDetail';
import ProgramCoupons from '../views/ProgramCoupons/ProgramCoupons';
import DashboardLayout from '../components/templates/DashboardLayout';
import StudentAbsences from '../views/StudentAbsences/StudentAbsences';
import TeacherAvailability from '../pages/Teachers/TeacherAvailability';
import { EditStudents } from '../views/Students/EditStudents/EditStudents';
import ReporteAtrasos from '../views/Rooms/ReporteAtrasos/ReporteAtrasos';
import { CreateTeacher } from '../views/Teachers/CreateTeacher/CreateTeacher';
import TeacherAttendances from '../views/TeacherAttendances/TeacherAttendances';
import OrganizationDetail from '../views/CTCB2B/Organizations/OrganizationDetail';
import RedirectSessionZoom from '../views/Redirects/RedirectSessionZoom/RedirectSessionZoom';

interface Props {
  isAuthenticated: boolean;
}

const Router: React.FC<Props> = ({ isAuthenticated }) => {
  return (
    <BrowserRouter>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="groups">
                <Route index element={<Groups />} />
                <Route path=":groupId/rooms" element={<GroupRooms />} />
              </Route>
              <Route path="rooms">
                <Route index element={<Rooms />} />
                <Route path=":roomId" element={<RoomDetailsV2 />} />
                <Route path="reporte-atrasos" element={<ReporteAtrasos />} />
              </Route>
              <Route path="students">
                <Route index element={<Students />} />
                <Route
                  path=":studentId"
                  element={<EditStudents isDetail={true} />}
                />
                <Route path=":studentId/edit" element={<EditStudents />} />
                <Route path=":guardianId/create" element={<EditStudents />} />
              </Route>

              <Route path="ctcb2b">
                <Route index element={<CTCB2B />} />
                <Route path=":projectId/projects" element={<ProjectDetail />} />
                <Route
                  path=":organizationId/organizations"
                  element={<OrganizationDetail />}
                />
              </Route>
              <Route path="guardians" element={<Guardians />} />
              <Route path="invoices" element={<Invoices />} />

              <Route path="teachers">
                <Route index element={<Teachers />} />
                <Route path="create" element={<CreateTeacher />} />
                <Route path="edit/:teacherId" element={<CreateTeacher />} />
                <Route path="teacher-availability">
                  <Route index element={<TeacherAvailability />} />
                  <Route
                    path="teacher-profile/:teacherId"
                    element={<CreateTeacher isProfile={true} />}
                  />
                </Route>
              </Route>

              <Route path="courses" element={<Courses />} />
              <Route path="learning-paths" element={<LearningPaths />} />
              <Route path="landings">
                <Route index element={<Landings />} />
                <Route path=":landingId" element={<LandingDetail />} />
              </Route>

              <Route path="sliders" element={<Sliders />} />
              <Route path="program-coupons" element={<ProgramCoupons />} />
              <Route path="age-groups" element={<AgeGroups />} />
              <Route path="live" element={<Live />} />
              <Route path="discounts" element={<Discounts />} />

              <Route path="student-absences" element={<StudentAbsences />} />
              <Route
                path="teacher-attendances"
                element={<TeacherAttendances />}
              />
            </Route>

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<SignIn />} />
            <Route path="/class-redirect" element={<RedirectSessionZoom />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
