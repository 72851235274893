import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getSessionLink } from '../../../services/rooms';
import Redirect from '../../../components/organism/redirect/Redirect';
import { IRedirectSession } from '../../../interfaces/room.interface';

const dataSessionRedirect = {
  hasSession: {
    iconName: 'loader',
    title: 'Redirigiéndote a la clase en Zoom...',
    description:
      'Por favor, espera un momento mientras te conectamos a tu sesión.',
    note: null
  },
  unavailable: {
    iconName: 'signal_disconnected',
    title: 'Acceso temporalmente no disponible',
    description:
      'Estamos teniendo problemas técnicos que impiden el acceso a la clase en este momento. Nuestro equipo está trabajando para solucionar el inconveniente lo más pronto posible.',
    note: '¡Vuelve a intentar en un rato!'
  },
  noClass: {
    iconName: 'release_alert',
    title: 'No hay clases programadas para hoy',
    description: 'La próxima sesión activa es: XX/XX/XXXX a las XX:XX hs.',
    note: '¡Nos vemos pronto!'
  },
  classCancelled: {
    iconName: 'event_busy',
    title: 'La clase de hoy fue cancelada',
    description:
      'Mientras tanto, no olviden revisar cómo continúa el calendario académico para estar al tanto de las próximas actividades y fechas importantes.',
    note: '¡Nos vemos en la próxima sesión!'
  }
} as const;

const RedirectSessionZoom = () => {
  const location = useLocation();

  type SessionType = keyof typeof dataSessionRedirect;
  const [type, setType] = useState<SessionType>();
  const [error, setError] = useState<boolean>();
  const [dataSession, setDataSession] = useState<IRedirectSession>();
  const queryParams = new URLSearchParams(location.search);
  const idRoom = queryParams.get('room') ?? '';
  const userType = queryParams.get('type') ?? '';

  useEffect(() => {
    if (idRoom && userType) {
      getLinkSession();
    }
  }, [idRoom, userType]);

  const getLinkSession = async () => {
    try {
      const response = await getSessionLink({ idRoom, userType });
      if (response.data.link === 'No hay clase hoy') {
        setType('classCancelled');
      } else if (response.data.state === false) {
        setType('noClass');
      } else if (
        response.data.link.includes('https://') &&
        response.data.state === true
      ) {
        setType('hasSession');
        setTimeout(() => {
          window.location.href = response.data.link;
        }, 200000);
      }
      setDataSession(response.data);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      setError(true);
      if (error) {
        setType('unavailable');
      }
    }
  };

  if (!type || error) {
    return (
      <Redirect
        iconName={dataSessionRedirect.unavailable.iconName}
        title={dataSessionRedirect.unavailable.title}
        description={dataSessionRedirect.unavailable.description}
        note={dataSessionRedirect.unavailable.note}
      />
    );
  }

  if (type === 'noClass') {
    return (
      <Redirect
        iconName={dataSessionRedirect.unavailable.iconName}
        title={dataSessionRedirect.unavailable.title}
        description={`La próxima sesión activa es: ${dataSession?.date} a las ${dataSession?.time} hs.`}
        note={dataSessionRedirect.unavailable.note}
      />
    );
  }

  return (
    <div>
      <Redirect
        iconName={dataSessionRedirect[type as SessionType].iconName}
        title={dataSessionRedirect[type as SessionType].title}
        description={dataSessionRedirect[type as SessionType].description}
        note={dataSessionRedirect[type as SessionType].note}
      />
    </div>
  );
};

export default RedirectSessionZoom;
